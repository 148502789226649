// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .aiz-side-nav-wrap.list {
    width: 100%;
}

#dropdownList {
    position: relative;
}

#dropdownList a {
    color: #333;
    padding: 6px 12px;
}

#dropdownList .dropdownMenu {
    position: absolute;
    top: -100%;
    left: 50;
    width: 100%;
    background: darkgrey;
    opacity: 0;
    transition: .4s ease-in-out;
}

#dropdownList .dropdownMenu ul li {
    color: #333;
    padding: 6px 12px;
    list-style: none;
}

#dropdownList:hover .dropdownMenu {
    top: 0;
    opacity: 1;
} */`, "",{"version":3,"sources":["webpack://./src/Admin-pannel/Components/asideAdim/AsideAdmin.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAgCG","sourcesContent":["/* .aiz-side-nav-wrap.list {\r\n    width: 100%;\r\n}\r\n\r\n#dropdownList {\r\n    position: relative;\r\n}\r\n\r\n#dropdownList a {\r\n    color: #333;\r\n    padding: 6px 12px;\r\n}\r\n\r\n#dropdownList .dropdownMenu {\r\n    position: absolute;\r\n    top: -100%;\r\n    left: 50;\r\n    width: 100%;\r\n    background: darkgrey;\r\n    opacity: 0;\r\n    transition: .4s ease-in-out;\r\n}\r\n\r\n#dropdownList .dropdownMenu ul li {\r\n    color: #333;\r\n    padding: 6px 12px;\r\n    list-style: none;\r\n}\r\n\r\n#dropdownList:hover .dropdownMenu {\r\n    top: 0;\r\n    opacity: 1;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
