import React from 'react'
import DownloadBeneficiaryDivDistWiseComp from '../../Components/downloadBeneficiaryDivDustWiseComp/DownloadBeneficiaryDivDistWiseComp'

function DownloadBeneficiaryDivDistWisePage() {
    return (
        <>
            <DownloadBeneficiaryDivDistWiseComp />
        </>
    )
}

export default DownloadBeneficiaryDivDistWisePage