// import SpecialSellerList from "../../Components/specialSeler/specialSellerList/specialSellerList"

import SpecialSellerList from "../../Components/specialSeler/specialSellerList/specialSellerList"


function SpecialSellerListPage() {
    return (
        <>
            <SpecialSellerList />
        </>
    )
}
export default SpecialSellerListPage