import { useState } from "react";
import { Link } from "react-router-dom";
import './AsideAdmin.css';

function AsideAdmin() {
  const [megocartpro, setmegocartPro] = useState(false);

  // const { data: pickup, isLoading: isLoadingPick } = useGetPickupPointQuery(window.localStorage.getItem('token'))
  // const isSuperAdminLogin = window.localStorage.getItem('showMainadmin')
  // const isSellerLogin = window.localStorage.getItem('isSellerLogin')
  // const isDelevery = window.localStorage.getItem('isDeleveryBoy');

  const idShow = window.localStorage.getItem('adminId');
  // const adminIslogin = window.localStorage.getItem('adminIslogin');

  if (idShow == '65487ea9410b557887eef2e9' || idShow == '6517c45776fb0f641d7ac2f7') {
    return <div className="aiz-sidebar left c-scrollbar c-scrollbar-2" style={{ backgroundColor: "#e1e1db" }}>
      <div className="aiz-side-nav-logo-wrap">
        <a href="https://mmslfashions.in/admin" className="d-block text-left">
          {/* <img className="mw-100" src={"https://mmslfashions.in/public/assets/img/logo.png"} alt /> */}
        </a>
      </div>
      <div className="aiz-side-nav-wrap list">
        <div className="px-20px mb-3">
          <input className="form-control bg-soft-secondary border-0 form-control-sm text-white" type="text" placeholder="Search in menu" id="menu-search" fdprocessedid="axe2ae" />
        </div>

        <ul className="aiz-side-nav-list metismenu" id="main-menu" data-toggle="aiz-side-menu">
          <li className="aiz-side-nav-item">
            <Link to="#" className="aiz-side-nav-link" onClick={() => { setmegocartPro(!megocartpro) }}>
              <i className="las la-user-tie aiz-side-nav-icon" />
              <span className="aiz-side-nav-text">Beneficiary / लाभार्थी</span>
              <span className="aiz-side-nav-arrow" />
            </Link>
            <li className="aiz-side-nav-item">
                <Link to="beneficiariesDivWise" className="aiz-side-nav-link ">
                  <span className="labour-charge-type">Beneficiaries Division Wise</span>
                </Link>
              </li>
            <ul className={`aiz-side-nav-list level-2 mm-collapse ${megocartpro ? "mm-show" : "extra"}`}>
              <li className="aiz-side-nav-item">
                <Link to="" className="aiz-side-nav-link ">
                  <span className="labour-charge-type">Dashbord /डॅशबोर्ड</span>
                </Link>
              </li>
              <li className="aiz-side-nav-item">
                <Link to="special-seller-list" className="aiz-side-nav-link ">
                  <span className="labour-charge-type">Beneficiary List / लाभार्थी यादी</span>
                </Link>
              </li>
              <li className="aiz-side-nav-item">
                <Link to="reasonMater" className="aiz-side-nav-link ">
                  <span className="labour-charge-type">Reason Master / कारण मास्टर</span>
                </Link>
              </li>
              <li className="aiz-side-nav-item">
                <Link to="division" className="aiz-side-nav-link ">
                  <span className="labour-charge-type">Division / विभाग </span>
                </Link>
              </li>
              <li className="aiz-side-nav-item">
                <Link to="district" className="aiz-side-nav-link ">
                  <span className="labour-charge-type">District / जिल्हा</span>
                </Link>
              </li>
              <li className="aiz-side-nav-item">
                <Link to="application_track" className="aiz-side-nav-link ">
                  <span className="labour-charge-type">Application Tracking / अनुप्रयोग ट्रॅकिंग</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="aiz-side-nav-item">
            <Link to="reasonMaster" className="aiz-side-nav-link ">
              <span className="aiz-side-nav-text">Reason  Master</span>
            </Link>
          </li>
          <li className="aiz-side-nav-item">
            <Link to="login_setting" className="aiz-side-nav-link ">
              <span className="aiz-side-nav-text">Login Setting</span>
            </Link>
          </li>
          <li className="aiz-side-nav-item">
            <Link to="add_popup" className="aiz-side-nav-link ">
              <span className="aiz-side-nav-text">Add Popup</span>
            </Link>
          </li>
          <li className="aiz-side-nav-item">
            <Link to="list_popup" className="aiz-side-nav-link ">
              <span className="aiz-side-nav-text">List Popup</span>
            </Link>
          </li>
          <li className="aiz-side-nav-item">
            <Link to="categories" className="aiz-side-nav-link ">
              <span className="aiz-side-nav-text">Categories</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  };
}
export default AsideAdmin