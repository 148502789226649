import React from 'react';
import axios from "axios"
import { useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
// import { base_Url } from "../../../../server"
import { useEffect } from "react"
import { GrView } from 'react-icons/gr'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Multiselect from "multiselect-react-dropdown"
import { AiOutlineDownload } from "react-icons/ai"
import html2pdf from 'html2pdf.js';
// import { useGetDistrictQuery, useGetDivitionQuery } from "../../all-products/allproductsApi/allProductsApi"
// import ImgShowModal from "../ImgShowModal"
import { Button, Form } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import { FcApproval, FcApprove, FcDisapprove } from "react-icons/fc"
import { MdPending } from "react-icons/md"
import { VscFilePdf } from "react-icons/vsc"
// import html2canvas from "html2canvas"
// import jsPDF from "jspdf"
// import ModalSelected from "./ModalSelected"
import { Pagination } from "antd";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

function DownloadBeneficiaryDivDistWiseComp() {
    const [spinn, setSpinn] = useState(false)
    const [alldivisionD, setAllDivisionD] = useState();
    const [districtD, setDistrictD] = useState();
    const [finalDivisionD, setFinalDivisionD] = useState();
    const [finalDistrictD, setFinalDistrictD] = useState();
    const [finalFileD, setFinalFileD] = useState();
    const doc = new jsPDF()
    const [listBeneficiariesD, setListBeneficiariesD] = useState(null)
    const token = window.localStorage.getItem('token');

    const getAllDivisionD = async () => {
        setSpinn(true)
        try {
            const res = await axios.get(`https://evehicleadmin.mshfdc.co.in/api/division`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + token
                },
            });
            setAllDivisionD(res?.data)
            setSpinn(false)
        } catch (error) {
            setSpinn(false)
            alert(error?.message)
        }
    };
    useEffect(() => {
        getAllDivisionD();
    }, []);
    const PostDivisionD = async (e) => {
        setSpinn(true)
        const selectedDiv = e.target.value;
        setFinalDivisionD(selectedDiv)
        try {
            const res = await axios.post(`https://evehicleadmin.mshfdc.co.in/api/district/division`, { Division: [selectedDiv] }, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + token
                },
            });
            setDistrictD(res?.data)
            setSpinn(false)
        } catch (error) {
            setSpinn(false)
            alert(error?.message)
        }
    };
    const handleChnageDistrict = (e) => {
        const selectedDist = e.target.value;
        setFinalDistrictD(selectedDist);
    };

    const HandleChooseFile = (e) => {
        let finalFile = e.target.files[0]
        setFinalFileD(finalFile)
        console.log('finalFileD--', finalFile)
    };
    const submitData = async () => {
        setSpinn(true)
        const formData = new FormData();
        formData.append('file', finalFileD);
        formData.append('division', finalDivisionD);
        formData.append('district', finalDistrictD);
        try {
            const res = await axios.post(`https://evehicleadmin.mshfdc.co.in/api/sellerDoc/add_doc`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            getListTableData();
            setSpinn(false)
        } catch (error) {
            setSpinn(false)
            alert(error?.message)
        };
    };

    const getListTableData = async () => {
        setSpinn(true)
        try {
            const resp = await axios.get('https://evehicleadmin.mshfdc.co.in/api/sellerDoc', {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + token
                },
            });
            setListBeneficiariesD(resp?.data)
            setSpinn(false)
        } catch (error) {
            setSpinn(false)
            alert(error?.message)
        }
    };
    useEffect(() => {
        getListTableData()
    }, []);

    // const exportPdfHandler = () => {
    //     doc.autoTable({ html: '#beneficiaryTable' });
    //     doc.save('Beneficiary.Pdf')
    // };

    // console.log('listBeneficiariesD---', listBeneficiariesD)

    return (
        <>
            <div className="px-15px px-lg-25px">
                <div className="aiz-titlebar text-left mt-2 mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h1 className="h2">Beneficiary List </h1>
                            <h3 className="h6">Self-Employment To Divyangjan Through Various Businesses on Environmentally Friendly E-Vehicles / E-Cart
                                \ पर्यावरणपूरक ई-वाहने/ई-कार्टवर विविध व्यवसायांद्वारे दिव्यांगजनांना स्वयंरोजगार
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <section className="form-section">
                            <div className="row">
                                {spinn && <div className="preloaderCount">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>}

                                <div className="col-lg-4">
                                    <div>
                                        <label htmlFor="">Select Division</label>
                                        <select className="form-select" aria-label="Default select example" onChange={PostDivisionD}>
                                            <option selected>Select Division</option>
                                            {alldivisionD && alldivisionD.map((item, i) => {
                                                return <option value={item?.uid} key={i}>{item?.title}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div>
                                        <label htmlFor="">Select District</label>
                                        <select className="form-select" aria-label="Default select example" onChange={handleChnageDistrict}>
                                            <option selected>Select District</option>
                                            {districtD && districtD.map((item, i) => {
                                                return <option value={item?.uid} key={i}>{item?.title}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div>
                                        <label htmlFor="">Choose File</label>
                                        <input className='form-control' type='file' onChange={HandleChooseFile} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <button className='btn-primary' type='button' onClick={submitData} style={{ margin: '10px 0', padding: '5px 7px', border: 'none' }}>submit</button>
                                    </div>
                                </div>

                            </div>
                        </section>


                        <div className="mainsel" style={{ overflow: "auto", marginTop: '60px' }}>
                            {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <button className='btn-primary' type='button' onClick={exportPdfHandler} style={{ margin: '10px 0', padding: '5px 7px', border: 'none' }}>Download Pdf</button>
                            </div> */}
                            <table className="table table-3" id="beneficiaryTable">
                                <thead>
                                    <tr>
                                        <th className="table-secondary" scope="col">#</th>
                                        <th className="table-secondary" scope="col">Division</th>
                                        <th className="table-secondary" scope="col">District</th>
                                        <th className="table-secondary" scope="col">Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listBeneficiariesD && listBeneficiariesD.map((item, i) => {
                                        return <tr className="table-to-xls" key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item?.division}</td>
                                            <td>{item?.district}</td>
                                            <td>

                                                {/* <img style={{ width: '100%', height: '400px' }} src={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/pdf2image/${item?.url}`} alt='img' /> */}

                                                {/* <iframe src={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/pdf2image/${item?.url}`} width="300px" height="300px" frameborder="0"></iframe> */}

                                                <iframe width="100%" height="600px" src={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/${item?.url}#toolbar=0`} >
                                                </iframe>

                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white text-center py-3 px-15px px-lg-25px mt-auto">
            </div>
        </>
    )
}

export default DownloadBeneficiaryDivDistWiseComp