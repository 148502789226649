import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/media.css";
import "./assets/css/style.css";
import Header from "./common/header/Header";
import "./assets/all-pannel-css/css/vendors.css";
import "./assets/all-pannel-css/css/stylesheets.css";
import "./assets/all-pannel-css/css/aiz-core.css";
import "./assets/all-pannel-css/css/custom-style.css";
// import "./Admin-pannel/Components/crmNew/assets/styles/main.css";
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import HomePage from './pages/home';
import DownloadBeneficiaryDivDistWisePage from "./Admin-pannel/Pages/downloadBeneficiarisDivDistWisePage";
import Footer from './common/footer/Footer';
import DashboardRightSectionAdmin from "./Admin-pannel/Pages/dashboardRightSection";
import DashboardAdminPage from "./Admin-pannel/Pages/dashboardPage";
import Application_track from "./Admin-pannel/Components/specialSeler/Tracker/Application_track";
import ListPopups from "./Admin-pannel/Pages/add_popup/ListPopups";
import SpecialSellerListPage from "./Admin-pannel/Pages/specialSellerList/Index";
import ReasionMaster from "./Admin-pannel/Pages/specialSellerList/ReasionMaster";
import Division_and_districtAdd from "./Admin-pannel/Components/specialSeler/division_and_districts/Division_and_districtAdd";
import Districs from "./Admin-pannel/Components/specialSeler/dstrict/Districs";
import SpecialSellerDetailsPage from "./Admin-pannel/Pages/specialSellerList/specialSellerDetails";
import LoginSetting from "./Admin-pannel/Pages/loginsetting/LoginSetting";
import AddPopups from "./Admin-pannel/Pages/add_popup/AddPopups";
import LoginSection from "./loginPage/LoginAdmin";
import Profile from "./Admin-pannel/Pages/Profile/Profile";
import CategoriesPageAdmin from "./Admin-pannel/Pages/categoriesPageAdmin";
import AddnewCategories from "./Admin-pannel/Components/categories/addnewCategories/AddnewCategories";
import Maintenance from "./pages/maintainence/Maintenance";

function App() {
  const [show, setshow] = useState(true);

  return (
    <>
      {/* {show && <Header />} */}
      <Routes>
        <Route path="/loginPage" element={<LoginSection setshow={setshow} />} />
        {/* <Route path="/loginPage" element={<Maintenance setshow={setshow} />} /> */}
        <Route path="/" element={<Navigate to="/loginPage" />} />
        {/* <Route path="/" element={<Maintenance/>} /> */}
        <Route path="/admin" element={<DashboardRightSectionAdmin setshow={setshow} />}>
          <Route path="/admin" element={<DashboardAdminPage />} />
          <Route path="login_setting" element={<LoginSetting />} />
          <Route path="special-seller-list" element={<SpecialSellerListPage />} />
          <Route path="reasonMaster" element={<ReasionMaster />} />
          <Route path="special-seller-list/:id" element={<SpecialSellerListPage />} />
          <Route path="division" element={<Division_and_districtAdd />} />
          <Route path="division/edit/:id" element={<Division_and_districtAdd />} />
          <Route path="district" element={<Districs />} />
          <Route path="district/edit/:id" element={<Districs />} />
          <Route path="application_track" element={<Application_track />} />
          <Route path="special-seller/details/:id" element={<SpecialSellerDetailsPage />} />
          {/*<Route path="login_setting" element={<LoginSetting />} />*/}
          <Route path="add_popup" element={<AddPopups />} />
          <Route path="list_popup/edit_popup/:id" element={<AddPopups />} />
          <Route path="list_popup" element={<ListPopups />} />
          <Route path="profile" element={<Profile />} />
          <Route path="categories" element={<CategoriesPageAdmin />} />
          <Route path="categories/create" element={<AddnewCategories />} />
          <Route path="categories/edit/:id" element={<AddnewCategories />} />
          <Route path="beneficiariesDivWise" element={<DownloadBeneficiaryDivDistWisePage />} />
          
          
        </Route>
      </Routes >
      {/* {show && <Footer />
} */}
    </>
  );
}
export default App;
