// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.para{
    color: black;
}
.main{
    background-color: rgb(234, 133, 133);

}`, "",{"version":3,"sources":["webpack://./src/pages/maintainence/maintainence.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,oCAAoC;;AAExC","sourcesContent":[".para{\r\n    color: black;\r\n}\r\n.main{\r\n    background-color: rgb(234, 133, 133);\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
